import React from "react"
import { Btn } from "@components"

export default function Hero() {
  return (
    <div className="hero">
      <div className="container hero-container">
        <div className="hero-content">
          <h1 data-aos="fade-right" className="hero-title">
            Um aplicativo que seus clientes vão amar            
          </h1>
          <h3 className="hero-subtitle">
            Roteiros e vouchers no celular, antes e durante a viagem.
          </h3>
          <div data-aos="zoom-out" className="text-center text-left-desktop">
            <Btn href="https://app.itineo.com.br/cadastro">Experimente 10 dias grátis</Btn>
          </div>
        </div>
        <div class="hero-images">
          <img
            src="/hero/macbook-mock.png"
            role="presentation"
            className="hero-image is-1"
          />
          <img
            src="/hero/app.png"
            role="presentation"
            className="hero-image is-2"
          />
          <img
            src="/hero/guy.png"
            role="presentation"
            className="hero-image is-3"
          />
        </div>
      </div>
    </div>
  )
}
