import React from "react"
import { Icon, Contact } from "@components"

export default function Footer() {
  return (
    <div className="footer">
      <div className="container footer-container">
        <div>
          <div className="contact-info">
            <div className="contact-info-item">
              <div className="contact-info-title">Nos encontre</div>
              <div className="contact-info-content">
                <address>
                  <Icon name="map" />
                  R. Ceará, 1425 - Alto do Parque, Lajeado - RS
                </address>
              </div>
            </div>
            <div className="contact-info-item">
              <div className="contact-info-title">Fale com a gente</div>
              <div className="contact-info-content">
                <Icon name="whatsapp" />{" "}
                <a
                  href="https://api.whatsapp.com/send?phone=5551991722494"
                  target="_blank"
                  rel="noreferrer"
                >
                  +55 51 99172-2494
                </a>
              </div>
              <div className="contact-info-content">
                <Icon name="phone" />{" "}
                <a href="tel:555137264200">+55 51 3726-4200</a>
              </div>
              <div className="contact-info-content">
                <Icon name="envelope" />{" "}
                <a href="mailto:erica@itineo.com.br">erica@itineo.com.br</a>
              </div>
              <div className="contact-info-content">
                <Icon name="instagram" />{" "}
                <a
                  href="https://www.instagram.com/itineo.app/"
                  target="_blank"
                  rel="noreferrer"
                >
                  @itineo.app
                </a>
              </div>
            </div>
            <div className="contact-info-item">
              <div className="contact-info-title">Horários</div>
              <div className="contact-info-content">Seg-Sex: 08:00-18:00</div>
            </div>
          </div>
          <div className="footer-apps">
            <a
              href="https://play.google.com/store/apps/details?id=br.com.itineo.app"
              target="_blank"
              rel="noreferrer"
            >
              <img
                src="platforms/googleplay.png"
                height="40"
                alt="Google Play"
                className="mr-1"
              />
            </a>
            <a
              href="https://apps.apple.com/br/app/itineo/id1519064567"
              target="_blank"
              rel="noreferrer"
            >
              <img src="platforms/appstore.png" height="40" alt="AppStore" />
            </a>
          </div>
        </div>
        <Contact />
      </div>
      <div className="footer-copyright">
        Itineo App 2021®. Todos os direitos reservados.
      </div>
    </div>
  )
}
