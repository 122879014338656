import React from "react"
import classNames from "classnames"

export default function Section({
  name,
  title,
  subtitle,
  children,
  image,
  variant,
  half = false,
  narrow = false,
  inverted = false,
}) {
  return (
    <section
      id={name}
      className={classNames(
        "section",
        { "is-half": half },
        { "is-narrow": narrow },
        { "is-inverted": inverted },
        variant ? `is-${variant}` : "",
        { "has-noimg": !image }
      )}
    >
      <div className="container">
        {title && <h6 className="section-title">{title}</h6>}
        {subtitle && <div className="section-subtitle">{subtitle}</div>}
        <div className="section-content">
          {image && (
            <div data-aos="fade-up" className="section-img">
              <img src={image} className="img-fluid" alt={title} />
            </div>
          )}
          <div className="section-slot">{children}</div>
        </div>
      </div>
    </section>
  )
}
