import React from "react"

export default function Feature({ title, image, content }) {
  return (
    <div className="feature">
      <img src={image} className="feature-img" />
      <h6 className="feature-title">{title}</h6>
      <div className="feature-content">{content}</div>
    </div>
  )
}
