import React, { useEffect } from "react"
import AOS from "aos"
import { Header, Footer, WhatsappBtn } from "@components"

import "@scss/app.scss"

export default function Default({ children }) {
  useEffect(() => {
    AOS.init()
  })

  return (
    <div className="layout is-default">
      <Header />
      <main className="main">{children}</main>
      <Footer />
      <WhatsappBtn />
    </div>
  )
}
