import React from "react"
import { Btn } from "@components"

export default function Plan({ name, price, maxUsers, oldPrice }) {
  return (
    <div data-aos="fade-in" className="plan">
      <h5 className="plan-name">{name}</h5>
      <div className="plan-users">
        {"Total de acessos: "}{maxUsers}
        <br />
        <br />
        {maxUsers > 1 ? "Ideal para agências de viagem" : "Ideal para agentes freelancers"}
      </div>
      <br />
      <div className="plan-price is-old">
        <span>R$</span>
        {oldPrice}
        <span>/mês</span>
      </div>
      <div className="plan-price">
        <span>R$</span>
        {price}
        <span>/mês</span>
      </div>
      {maxUsers > 1 ? "(menos de R$7,00 por dia)" : "(menos de R$4,00 por dia)"}
      <div className="mt-4">
        <Btn href="https://app.itineo.com.br/cadastro">Experimente agora</Btn>
      </div>
    </div>
  )
}
