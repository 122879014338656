import React, { useEffect, useState } from "react"
import { globalHistory } from "@reach/router"
import classNames from "classnames"
import { Btn, Nav, Brand, Toggler } from "@components"

export default function Header() {
  const [active, setActive] = useState(false)

  const onScroll = () => setActive(false)

  useEffect(() => {
    return globalHistory.listen(({ action }) => {
      if (action === "PUSH") setActive(false)
    })
  }, [setActive])

  return (
    <header className="header">
      <div className="container header-container">
        <Brand />
        <div className={classNames("header-collapse", { "is-active": active })}>
          <Nav onScroll={onScroll} />
          <div className="mt-4 mt-0-desktop">
            <Btn href="https://app.itineo.com.br/login">Login</Btn>
          </div>
        </div>
        <Toggler active={active} handleClick={() => setActive(!active)} />
      </div>
    </header>
  )
}
