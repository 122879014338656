import React from "react"

export default function Btn({
  href,
  type = "button",
  children,
  variant = "primary",
}) {
  const className = `btn btn-${variant}`

  if (href) {
    return (
      <a href={href} className={className}>
        {children}
      </a>
    )
  }

  return (
    <button type={type} className={className}>
      {children}
    </button>
  )
}
