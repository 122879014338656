import React, { useState } from "react"
import classNames from "classnames"

export default function Faq({ question, answer }) {
  const [active, isActive] = useState(false)
  return (
    <div className={classNames("faq", { "is-active": active })}>
      <div className="faq-question" onClick={() => isActive(!active)}>
        {question}
      </div>
      <div className="faq-answer">{answer}</div>
    </div>
  )
}
