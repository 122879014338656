import React, { useState, useEffect } from "react"
import SwiperCore, { Navigation } from "swiper"
import { Swiper, SwiperSlide } from "swiper/react"

import Layout from "@layouts/default"
import {
  Btn,
  Faq,
  Testimonial,
  Seo,
  Icon,
  Hero,
  Badge,
  Plan,
  Section,
  Feature,
  Advantage,
} from "@components"

SwiperCore.use([Navigation])

const AgencyBadge = () => (
  <Badge>
    <Icon name="company" /> Para a agência
  </Badge>
)

const PassengerBadge = () => (
  <Badge>
    <Icon name="user" /> Para o passageiro
  </Badge>
)

const plans = [
  {
    name: "Plano individual",
    price: 119,
    maxUsers: 1,
    oldPrice: 179,
  },
  {
    name: "Plano equipe",
    price: 199,
    maxUsers: 7,
    oldPrice: 239,
  },
]

const TESTIMONIALS = [
  {
    name: "Roberta Santos",
    image: "/testimonials/roberta-santos.png",
    job: "Utilizou o App Itineo",
    content: `É ótimo ter tudo no celular. Todos os meus vouchers, roteiros, ingressos estavam sempre à mão. Já aconteceu em outras viagens de eu perder parte dos vouchers. Era muito papel. Agora ficou muito mais prático!<br>
    Adoro o Itineo. Minha agência de viagens utiliza e me indicou e agora não vivo mais sem.`,
  },
  {
    name: "Andreia Schmitt",
    image: "/testimonials/andreia-schmitt.png",
    job: "Agente de Viagens Independente",
    content: `Utilizo o Itineo para administrar todos as viagens dos meus clientes. O programa facilitou muito a minha vida, pois trabalho sozinha e sempre tenho milhões de coisas para fazer.<br>Meus clientes gostam muito e sempre elogiam minha agilidade`,
  },
  {
    name: "Mug Viagens",
    image: "/testimonials/mug-viagens.png",
    job: "Agencia de Viagens no Rio Grande do Sul",
    content: `Somos uma agencia pequena mais ainda assim gastávamos muito com material de viagem, vouchers e correio. O Itineo nos fez economizar muito nesses gastos e ainda tornou a entrega do roteiro de viagem algo muito mais prático. Ganhamos mais tempo para focar nas vendas e nos clientes.`,
  },
  {
    name: "Isabella Morais",
    image: "/testimonials/isabella-morais.png",
    job: "",
    content: `O Itineo é uma plataforma completa. Utilizo para planejar a viagem dos meus clientes e eles gostam muito, pois conseguem visualizar no app. Não fico mais perdida nos e-mails é tudo pela plataforma.`,
  },
  {
    name: "Murillo Alcantara",
    image: "/testimonials/murillo-alcantara.png",
    job: "",
    content: `Conseguir planejar e acompanhar os detalhes da viagem em um só lugar é excelente. Saber que é possível consultar todas as informações da sua viagem no app, reduz muito a dor de cabeça de ficar resgatando histórico de emails.`,
  },
]

const faq = [
  {
    question: "O que é o Itineo?",
    answer:
      "O Itineo é uma empresa de tecnologia que nasceu como o objetivo de facilitar o dia a dia do agente de viagens e do passageiro. Seu produto principal é a plataforma web para agentes de viagem e o aplicativo para passageiros. Pela plataforma o agente faz toda a gestão da viagem do cliente desde o planejamento, inserção de atividades ao roteiro até controle durante a viagem do cliente. Pelo aplicativo o passageiro pode visualizar sua viagem como também inserir fotos dos documentos.",
  },
  {
    question: "Qual a forma de pagamento do Itineo?",
    answer:
      "O modelo de negócio do Itineo é por assinatura com cobrança via cartão de crédito. O cancelamento pode ser feito a qualquer momento pela plataforma ou pelo número (51) 99172-2494 ou pelo e-mail erica@itineo.com.br",
  },
  {
    question: "Para quem é o Itineo?",
    answer:
      "O Itineo é para agentes ou agências de viagens que buscam fazer a gestão de forma fácil e sem burocracia das viagens dos seus clientes. Ao contratar o Itineo os seus clientes têm acesso ao aplicativo Itineo por onde poderá acompanhar a viagem criada pelos agentes ou agências.",
  },
  {
    question: "O passageiro paga para usar o app?",
    answer:
      "O passageiro não paga pelo app. Ele poderá baixar nas lojas de aplicativo App Store ou Google Play de forma gratuita. Assim que houver o registro de uma viagem por um agente ou agência que contratou o Itineo, ele poderá visualizar tudo pelo app.",
  },
  {
    question: "Como faço para cancelar?",
    answer:
      "Você pode solicitar o cancelamento pela plataforma na sessão pagamentos ou pelo número (51) 99172-2494 ou pelo e-mail erica@itineo.com.br",
  },
]

const features = [
  {
    image: "/illustrations/money.svg",
    title: "Mais dinheiro no seu bolso",
    content: "O App de Viagens Itineo foi pensado justamente para otimizar seu tempo e agilizar seu trabalho. Quanto mais você perde tempo resolvendo processos que poderiam ser automatizados mais dinheiro estará deixando na mesa."
  },
  {
    image: "/illustrations/opportunity.svg",
    title: "Oportunidade",
    content: "O App de Viagens Itineo é algo extremamente novo no mercado de turismo. Quanto antes você assinar, antes começa a aproveitar todas suas vantagens e benefícios. Isso significa colher frutos através do fator “novidade”, e literalmente sair na frente de qualquer concorrente."
  },
  {
    image: "/illustrations/freedom.svg",
    title: "Mais liberdade",
    content: "Ao focar toda operação do seu trabalho em um único lugar, além de tornar tudo mais prático, você insere também o elemento segurança em todos seus atendimentos. Assim você evita possíveis erros e fica menos preso resolvendo problemas."
  }
]

export default function IndexPage() {
  const [testimonials, setTestimonials] = useState([])

  useEffect(() => {
    setTestimonials(TESTIMONIALS)
  }, [setTestimonials])

  return (
    <Layout>
      <div className="page is-home">
        <Seo />
        <Hero />
        <Section
          name="depoimentos"
        >
          <h2 class="title text-center mb-5">Veja o que dizem as agências que já estão usando o aplicativo.</h2>
          <div className="page-testimonails">
            <Swiper
              loop
              navigation
              slidesPerView={1}
              spaceBetween={30}
              breakpoints={{
                992: {
                  width: 992,
                  slidesPerView: 3,
                },
              }}
            >
              {testimonials.map((item, index) => (
                <SwiperSlide key={index}>
                  <Testimonial {...item} />
                </SwiperSlide>
              ))}
            </Swiper>
          </div>
        </Section>
        
        <Section
          name="funcionalidades"
          image="/home/panel.png"
        >
          <h2 className="title">Painel de visualização de viagens</h2>
          <AgencyBadge />
          <p>
            Controle as viagens de todos os seus clientes em um só lugar. Nunca
            perca a viagem de nenhum cliente.
          </p>

          <PassengerBadge />
          <p>
            Visualize as viagens criadas para você por seu agente de viagens.
            Todas as viagens na palma da mão.
          </p>

          <div className="text-center text-left-desktop mt-5">
            <Btn href="https://app.itineo.com.br/cadastro">
              Experimente 10 dias grátis
            </Btn>
          </div>
        </Section>

        <Section name="roteiros" image="/home/itineraries.png" inverted>
          <h2 className="title">Roteiros detalhados e fáceis de criar</h2>
          <AgencyBadge />
          <p>
            Crie roteiros e adicione vouchers apenas com um clique para cada
            item da viagem..
          </p>

          <PassengerBadge />
          <p>
            Sua experiência de viagem nunca mais será a mesma. Visualize todo o
            itinerário criado pelo seu agente de forma fácil e visual, assim
            você não vai perder nenhuma atividade.
          </p>

          <div className="text-center text-left-desktop mt-5">
            <Btn href="https://app.itineo.com.br/cadastro">
              Experimente 10 dias grátis
            </Btn>
          </div>
        </Section>

        <Section name="vouchers" image="/home/vouchers.png">
          <h2 className="title">Vouchers digitais dos itens do roteiro</h2>
          <AgencyBadge />
          <p>
            Inclua e substitua vouchers digitais para todos os itens do roteiro
            a qualquer momento para o seu cliente estar sempre atualizado.
          </p>

          <PassengerBadge />
          <p>
            Não precisa carregar mais vouchers impressos. Acesse seus vouchers a
            qualquer momento pelo app.
          </p>

          <div className="text-center text-left-desktop mt-5">
            <Btn href="https://app.itineo.com.br/cadastro">
              Experimente 10 dias grátis
            </Btn>
          </div>
        </Section>

        <Section name="notificacoes" image="/home/notifications.png" inverted>
          <h2 className="title">Alertas e comunicados direto para o app</h2>
          <AgencyBadge />
          <p>
            Crie e envie alertas e comunicados personalizados ou pré formatados
            direto para o app do cliente.
          </p>

          <PassengerBadge />
          <p>
            Receba alertas importantes enviados pelo seu agente relacionados a
            sua viagem.
          </p>

          <div className="text-center text-left-desktop mt-5">
            <Btn href="https://app.itineo.com.br/cadastro">
              Experimente 10 dias grátis
            </Btn>
          </div>
        </Section>

        <Section name="checklist" image="/home/checklist.png">
          <h2 className="title">Organização de tarefas para cada viagem</h2>
          <AgencyBadge />
          <p>
            Organize tarefas internas por viagem do cliente. É fácil de inserir
            e quando concluir você sinaliza com um check.
          </p>

          <div className="text-center text-left-desktop mt-5">
            <Btn href="https://app.itineo.com.br/cadastro">
              Experimente 10 dias grátis
            </Btn>
          </div>
        </Section>

        <Section name="gastos" image="/home/investment.png" inverted>
          <h2 className="title">Visualização dos gastos do cliente por item</h2>
          <AgencyBadge />
          <p>
            Ao inserir o investimento em cada item do roteiro você pode
            visualizar o orçamento total e por item do roteiro. Fica mais fácil
            de controlar o orçamento do cliente.
          </p>

          <div className="text-center text-left-desktop mt-5">
            <Btn href="https://app.itineo.com.br/cadastro">
              Experimente 10 dias grátis
            </Btn>
          </div>
        </Section>

        <Section image="/home/profile.png">
          <h2 className="title">
            Perfil do cliente sempre atualizado e de fácil consulta
          </h2>
          <AgencyBadge />
          <p>
            Insira e consulte as informações sobre seu cliente e de seus
            dependentes a qualquer momento. Tenha os documentos deles em um
            único lugar..
          </p>

          <PassengerBadge />
          <p>
            Cadastre suas informações e de seus dependentes. Tenha seus
            documentos em um único lugar. Se precisar compartilhe os documentos
            com seu agente.
          </p>

          <div className="text-center text-left-desktop mt-5">
            <Btn href="https://app.itineo.com.br/cadastro">
              Experimente 10 dias grátis
            </Btn>
          </div>
        </Section>

        <Advantage />

        <Section
          name="planos"
          title="Escolha o plano ideal para você"
          variant="blue"
        >
          <div className="d-flex-desktop justify-content-around">
            {plans.map((item, index) => (
              <Plan key={index} {...item} />
            ))}
          </div>
        </Section>

        <Section
          variant="text-dark"
          title="Ainda está em dúvida?"
          subtitle="Confira 3 motivos para assinar o Itineo agora mesmo."
        >
          <div className="grid">
            {features.map(item => (
              <Feature
                title={item.title}
                image={item.image}
                content={item.content}
              />
            ))}
          </div>
        </Section>

        <Section
          name="planos"
          title="Escolha o plano ideal para você"
          variant="blue"
        >
          <div className="d-flex-desktop justify-content-around">
            {plans.map((item, index) => (
              <Plan key={index} {...item} />
            ))}
          </div>
        </Section>

        <Section
          name="faq"
          title="Perguntas frequentes"
          subtitle="Entenda todos os detalhes"
        >
          {faq.map((item, index) => (
            <Faq key={index} {...item} />
          ))}
        </Section>
      </div>
    </Layout >
  )
}
