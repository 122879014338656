import React from "react"
import classNames from "classnames"

export default function Toggler({ active, handleClick }) {
  return (
    <button
      type="button"
      onClick={e => handleClick(e)}
      className={classNames("toggler", {
        "is-active": active,
      })}
    >
      <div className="toggler-bar"></div>
      <div className="toggler-bar"></div>
      <div className="toggler-bar"></div>
    </button>
  )
}
