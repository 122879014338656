import React from "react"

export default function Testimonial({ name, job, image, content }) {
  return (
    <div className="testimonial">
      <header className="testimonial-header">
        <div className="testimonial-avatar">
          <img src={image} width="100" alt={name} />
        </div>
        <div className="testimonial-quote">“</div>
      </header>
      <div
        className="testimonial-content"
        dangerouslySetInnerHTML={{ __html: content }}
      />
      <div className="testimonial-name mt-4 mt-2-desktop">{name}</div>
      <div className="testimonial-job">{job}</div>
    </div>
  )
}
