import React from "react"

export default function Nav({ onScroll }) {
  function clickHandler(e) {
    const href = e.target.getAttribute("href")
    const header = document.querySelector(".header")
    const offsetTop = document.querySelector(href).offsetTop

    window.scroll({
      top: offsetTop - header.clientHeight,
      behavior: "smooth",
    })

    onScroll()

    e.preventDefault()
  }

  return (
    <ul className="nav">
      <li className="nav-item">
        <a href="#funcionalidades" onClick={e => clickHandler(e)}>
          Funcionalidades
        </a>
      </li>
      <li className="nav-item">
        <a href="#planos" onClick={e => clickHandler(e)}>
          Planos e Preço
        </a>
      </li>
      <li className="nav-item">
        <a href="#depoimentos" onClick={e => clickHandler(e)}>
          Depoimentos
        </a>
      </li>
      <li className="nav-item">
        <a href="#faq" onClick={e => clickHandler(e)}>
          FAQ
        </a>
      </li>
    </ul>
  )
}
