import React from "react"
import { Btn, Input, FormGroup } from "@components"

export default function Contact() {
  return (
    <div className="pnr">
      <h5 className="pnr-legend">Decodificador PNR</h5>

      <a className="btn btn-primary" href="https://app.itineo.com.br/decodificador-pnr" target="_blank">Acessar</a>
      <form
        method="POST"
        action="https://itineo.us1.list-manage.com/subscribe/post?u=181984d544f58f87629db63e4&id=02055a65d9"
      >
        <p className="pnr-lgpd">* gratuito para 20 acessos diários por IP.</p>
      </form>
    </div>
  )
}
