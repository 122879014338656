import React from "react"
import Section from "./Section";

export default function Advantage() {
  return (
    <Section narrow>
      <div className="advantage">
        <div className="advantage-content">
          <h3 className="title">Todas essas vantagens e benefícios em um único lugar através de uma única ferramenta.</h3>
          <h3 className="title title-h2 title-gray">Qual o valor do investimento?</h3>
          <div className="advantage-text">
            <p>Entenda: resolver problemas complexos de forma prática, otimizar a rotina de trabalho e aumentar o faturamento são metas de qualquer empresa e <b>muitas delas pagariam caro para atingi-las.</b></p>
            <p>Mas nós estamos disponibilizando uma oferta totalmente à prova de desculpas, através de duas modalidades diferentes de assinatura. Veja abaixo:</p>
          </div>
        </div>
        <div className="advantage-img">
          <img src="/illustrations/clouds.svg" data-aos="fade-left" alt="" className="advantage-clouds" />
          <img src="/home/app.png" alt="" data-aos="fade-up" className="advantage-app" />
        </div>
      </div>
    </Section>
  )
}
